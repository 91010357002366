import React, { useState } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import "./downloads.css"
import {useTranslation} from "react-i18next";

function Mp(){
    const { t } = useTranslation(['mp', 'base']);

    return(
        <div className='blocks'>
            <div className='body-box'>
                <div className='mission-download'>
                    <div className='mp-logo'>
                        <img src="/image/MP.png" />
                        
                    </div>
                    <div>
                        <div className='heading'>{t('header')}</div>
                        <div className='text'><p>{t('string_1')}</p>
                            <p>{t('string_2')} <a href='https://ardupilot.org/planner/'>{t('href_text')}</a></p>
                            <p>{t('string_3')}</p>
                            <div>{t('string_4')}</div>
                            <div>{t('string_5')}</div>
                            <div>{t('string_6')}</div>
                            <div>{t('string_7')}</div>
                            <div>{t('string_8')}</div>
                        </div>
                    </div>
                    <div className='download-buttons'>
                        <Button variant='success' href='https://disk.yandex.ru/d/1_9UD_hxlMXjMw' target='_blank'>{t('Download', { ns: "base"})}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mp;